import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const signingManagers = () => {
  return (
    <Layout>
      <SEO
        title="Signing Managers"
        description="Learn how to sign the best managers in myClub and the role of Assistant Coaches in PES 2021."
      />
      <div className="container">
        <h1>Signing Managers</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Signing Managers</li>
        </ul>
        <p>
          In other Pro Evolution Soccer Game Modes you can switch formations as
          much as you like, but in myClub, if you want a specific formation like
          4-3-3, you will need to sign a Manager that actually uses it.
        </p>
        <p>
          For this reason, I have created a{" "}
          <Link to="/managers/">list of Managers for PES myClub</Link>. Here you
          can find each manager's formation, cost and other relevant info such
          as Management Skills, Adaptability and Attacking/Defensive
          Instructions.
        </p>
        <p>
          <Link to="/best-managers/">
            You can also have a look at some of the most popular managers in PES
            here.
          </Link>
        </p>
        <p>
          When you’re just starting out, most top managers will be locked with
          coins - you won’t be able to spend GP to sign them. They will
          gradually become available as you play more and more (you will need to
          play/SIM hundreds of games - about 400 - to unlock the best managers).
        </p>
        <p>
          A cheaper manager will be easier to maintain, but one drawback is that
          he may not be able to accommodate all your superstar players.
          Naturally, you won’t experience this problem with a more expensive
          manager like Joachim Löw, but keep in mind that if you fail to fulfil
          his objectives, you will need to spend 9.5k GP every 25 games to keep
          using him.
        </p>
        <p>
          The <Link to="/managers/">list of available managers</Link> is
          frequently refreshed by Konami (every 8 hours) - so you may not see
          that manager again for a while. Bear in mind they can also return with
          different formations, so if you see the manager you want with the
          perfect formation, you should seize the opportunity.
        </p>
        <p>
          On the last day of each month, the list is actually refreshed every
          time you visit the Manager List screen - so this is usually a good day
          to grab that Manager that got away.
        </p>
        <h2>Assistant Coaches</h2>
        <p>
          You may want to use more than one formation in game - for instance, an
          offensive formation like 4-3-3 when you need a goal and a more
          defensive formation like 4-5-1 to secure the lead.{" "}
        </p>
        <p>
          That is possible, provided you have 2 different managers that use
          those specific formations.
        </p>
        <p>
          Each team can have up to 3 different presets. In your CLUB HOUSE /
          SQUAD MANAGEMENT screen, you will then need to do the following:
        </p>
        <ul>
          <li>
            assign one of the managers for your offensive formation, by
            switching to that Preset Tactic;
          </li>
          <li>
            assign the other manager for the defensive formation, by switching
            to that Preset Tactic.
          </li>
        </ul>
        <p>
          To easily switch during a game, you first need to go to GAMEPLAN and
          the “Support Settings” tab. There, make sure “Switch Preset Tactics”
          is set to ON.{" "}
        </p>
        <p>
          Then, you can long press UP in-game to use the offensive formation or
          long press DOWN for the defensive formation.
        </p>
      </div>
    </Layout>
  )
}

export default signingManagers
